<script setup lang="ts">
import { match } from 'ts-pattern'
import { z } from 'zod'

z.setErrorMap((issue) => {
  return {
    message:
      issue.message ??
      match(issue)
        .with(
          {
            code: 'invalid_string',
          },
          (i) =>
            match(i.validation)
              .with('email', () => 'Ungültige E-Mail-Adresse')
              .with('datetime', () => 'Ungültiges Datum')
              .otherwise(() => 'Ungültige Eingabe'),
        )
        .with(
          {
            code: 'too_big',
          },
          (i) =>
            i.type === 'number'
              ? `Wert darf nicht größer als ${i.maximum} sein`
              : `Text darf nicht länger als ${i.maximum} Zeichen sein`,
        )
        .with(
          {
            code: 'too_small',
          },
          (i) =>
            i.type === 'number'
              ? `Wert darf nicht kleiner als ${i.minimum} sein`
              : `Text darf nicht kürzer als ${i.minimum} Zeichen sein`,
        )
        .otherwise(() => 'Ungültige Eingabe'),
  }
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications />
  </div>
</template>
