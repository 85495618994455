import { default as index0KJcXeJIlnMeta } from "/app/packages/web/pages/index.vue?macro=true";
import { default as kategorienrbaLghitvWMeta } from "/app/packages/web/pages/kategorien.vue?macro=true";
import { default as leseplaeneNPzalpPmTcMeta } from "/app/packages/web/pages/leseplaene.vue?macro=true";
import { default as _91id_93DHOuZlt18RMeta } from "/app/packages/web/pages/leseplan/[id].vue?macro=true";
import { default as loginGZZLlpUN6mMeta } from "/app/packages/web/pages/login.vue?macro=true";
import { default as reviewszb5bXOiqgFMeta } from "/app/packages/web/pages/reviews.vue?macro=true";
import { default as usersyGbKDt4uZWMeta } from "/app/packages/web/pages/users.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/packages/web/pages/index.vue")
  },
  {
    name: "kategorien",
    path: "/kategorien",
    meta: kategorienrbaLghitvWMeta || {},
    component: () => import("/app/packages/web/pages/kategorien.vue")
  },
  {
    name: "leseplaene",
    path: "/leseplaene",
    meta: leseplaeneNPzalpPmTcMeta || {},
    component: () => import("/app/packages/web/pages/leseplaene.vue")
  },
  {
    name: "leseplan-id",
    path: "/leseplan/:id()",
    meta: _91id_93DHOuZlt18RMeta || {},
    component: () => import("/app/packages/web/pages/leseplan/[id].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginGZZLlpUN6mMeta || {},
    component: () => import("/app/packages/web/pages/login.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    meta: reviewszb5bXOiqgFMeta || {},
    component: () => import("/app/packages/web/pages/reviews.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: usersyGbKDt4uZWMeta || {},
    component: () => import("/app/packages/web/pages/users.vue")
  }
]